import { PrimaryButton, Stack } from '@fluentui/react';
import { ISlidingFeePlan } from 'api/models/slidingFee.model';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { v4 as uuid } from 'uuid';

export default function SlidingFeesToolbar() {
    const { setSelectedProgramPlan, toggleIsProgramPlanPanelOpen } = useSlidingFee();
    const createPlan = () => {
        setSelectedProgramPlan(newProgramPlan(), true);
        toggleIsProgramPlanPanelOpen();
    };

    return (
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }} grow>
            <PrimaryButton text="Add Plan" iconProps={{ iconName: 'Add' }} onClick={createPlan} />
        </Stack>
    );
}

function newProgramPlan(): ISlidingFeePlan {
    return {
        id: uuid(),
        name: '',
        adjustmentType: '',
        calculationType: '',
        codeCategoryExclusions: [],
        codeExclusions: [],
        codeRangeExclusions: [],
        effectiveDate: '',
        expirationDate: '',
        flatFee: 0,
        maxFee: 0,
        minFee: 0,
        patientPercentage: 0,
        povertyPercentageFrom: 0,
        povertyPercentageTo: 0,
    };
}
