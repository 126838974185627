import { DefaultButton, IDropdownOption, Label, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import ISlidingFeeProgram, {
    ICodeCategoryExclusion,
    ICodeExclusion,
    ICodeGroupExclusion,
    ISlidingFeePlan,
} from 'api/models/slidingFee.model';
import { Field } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProgramsPlanAsOption, selectSelectedProgramPlans } from 'state/slices/sliding-fee/sliding-fee.slice';
import { isDateBetween } from 'utils/isDateBetween';
import { v4 as uuid } from 'uuid';

function ProgramPlan(): JSX.Element {
    const {
        updateSlidingFeeProgram,
        isProgramPlanPanelOpen,
        selectedProgram,
        toggleIsProgramPlanPanelOpen,
        setSelectedProgramPlan,
        selectedProgramPlan,
    } = useSlidingFee();
    const [hasError, setHasError] = useState<boolean>(false);

    const [rangeOnlyOption, setRangeOnlyOption] = useState<IDropdownOption | undefined>();
    const [rangeRulesOption, setRangeRulesOption] = useState<IDropdownOption | undefined>();

    const programPlan = selectedProgramPlan.data;
    const isNew = selectedProgramPlan.isNew;
    const insertIndex = selectedProgramPlan.insertIndex;
    const textFieldId = useId('percentOfPoverty');
    const selectedPlanOption = useSelector(selectProgramsPlanAsOption);
    const selectedPlanList = useSelector(selectSelectedProgramPlans);

    const onProgramPlanPropertyChange = (
        key: keyof ISlidingFeePlan,
        value: string | number | ICodeCategoryExclusion[] | ICodeGroupExclusion[] | ICodeExclusion[],
    ) => {
        if (!programPlan) return;
        setSelectedProgramPlan({ ...programPlan, [key]: value }, isNew, insertIndex);
    };

    const _onDismiss = () => {
        setSelectedProgramPlan(undefined);
        setHasError(false);
        toggleIsProgramPlanPanelOpen();
        setRangeOnlyOption(undefined);
        setRangeRulesOption(undefined);
    };

    const _onSave = () => {
        if (selectedProgram && programPlan) {
            const program: ISlidingFeeProgram = {
                ...selectedProgram,
                plans:
                    selectedProgram.plans !== undefined ? [...selectedProgram.plans, { ...programPlan }] : [{ ...programPlan }],
            };
            updateSlidingFeeProgram(program);
        }
    };

    const _onRenderFooterContent = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <PrimaryButton text={'Save'} disabled={hasError} iconProps={{ iconName: 'SaveAndClose' }} onClick={_onSave} />
                <DefaultButton text="Cancel" onClick={_onDismiss} />
            </Stack>
        );
    };

    const nameHasError = !programPlan?.name ? 'Name is required.' : '';
    const isDuplicateWithExpiration =
        selectedProgram &&
        selectedProgram?.plans &&
        selectedProgram.plans.some(
            (plan) =>
                plan.name === programPlan?.name &&
                plan.expirationDate &&
                isDateBetween({
                    dateToCheck: programPlan?.effectiveDate,
                    start: plan.effectiveDate,
                    end: plan.expirationDate,
                }),
        );
    const isDuplicateWithNoExpiration =
        selectedProgram &&
        selectedProgram?.plans &&
        selectedProgram.plans.some(
            (plan) =>
                plan.name === programPlan?.name &&
                !plan.expirationDate &&
                isDateBetween({
                    dateToCheck: programPlan?.effectiveDate,
                    start: plan.effectiveDate,
                    end: plan.expirationDate,
                }),
        );
    const hasDuplicateWithNoExpirationError = isDuplicateWithNoExpiration
        ? 'Name is currently in use. Must first enter expiration date for the identical Plan name...'
        : '';
    const hasDuplicateWithExpirationError = isDuplicateWithExpiration
        ? 'Date ranges cannot overlap with an active Sliding Fee Plan of the same name...'
        : '';

    useEffect(() => {
        if (!programPlan?.name || isDuplicateWithExpiration || isDuplicateWithNoExpiration) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [programPlan, isDuplicateWithExpiration, isDuplicateWithNoExpiration]);

    return (
        <Panel
            isOpen={isProgramPlanPanelOpen}
            onDismiss={_onDismiss}
            headerText={isNew ? 'Create Plan' : 'Edit Plan'}
            type={PanelType.custom}
            customWidth="800px"
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <TextField
                    value={programPlan?.name}
                    label="Name"
                    description={'The name of your sliding fee scale/level example Slide A.'}
                    required
                    errorMessage={nameHasError || hasDuplicateWithNoExpirationError}
                    autoComplete="off"
                    onChange={(event, newValue) => onProgramPlanPropertyChange('name', newValue ? newValue : '')}
                    // onBlur={() => {
                    //     if (selectedProgram && selectedProgram?.plans) {
                    //         const isDuplicate = selectedProgram?.plans.some(
                    //             (plan) => plan.name === programPlan?.name,
                    //         );
                    //         console.log(isDuplicate);
                    //     }
                    // }}
                />
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Field.Date
                            value={programPlan?.effectiveDate || ''}
                            label="Effective Date"
                            onChange={(event, newValue) => {
                                onProgramPlanPropertyChange('effectiveDate', newValue ?? '');
                                //Prevent an expiration date from existing without an effective date.
                                if (!newValue && programPlan?.expirationDate) onProgramPlanPropertyChange('expirationDate', '');
                            }}
                            errorMessage={hasDuplicateWithExpirationError}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            value={programPlan?.expirationDate || ''}
                            label="Expiration Date"
                            disabled={!programPlan?.effectiveDate}
                            onChange={(event, newValue) => {
                                onProgramPlanPropertyChange('expirationDate', newValue ?? '');
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Label htmlFor={textFieldId}>Percent of Poverty</Label>
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <Stack.Item grow>
                            <TextField
                                type="number"
                                prefix="% from"
                                value={programPlan?.povertyPercentageFrom?.toString()}
                                autoComplete="off"
                                onChange={(e, value) => value && onProgramPlanPropertyChange('povertyPercentageFrom', value)}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <TextField
                                type="number"
                                prefix="% to"
                                value={programPlan?.povertyPercentageTo?.toString()}
                                autoComplete="off"
                                onChange={(e, value) => value && onProgramPlanPropertyChange('povertyPercentageTo', value)}
                            />
                        </Stack.Item>
                    </Stack>

                    <Field.Dropdown
                        label="Copy plan with exclusion ranges only"
                        placeholder="Copy Plan"
                        selectedKey={rangeOnlyOption?.key ?? ''}
                        options={[{ key: '', text: 'Select Plan' }, ...selectedPlanOption]}
                        onChange={(event, option) => {
                            const plan = selectedPlanList?.find((p) => p.name === option?.text);
                            if (option) {
                                //Make sure that both dropdowns are not selected at the same time
                                setRangeOnlyOption(option);
                                setRangeRulesOption(undefined);
                            }
                            setSelectedProgramPlan({
                                ...programPlan,
                                id: uuid(),
                                adjustmentType: plan?.adjustmentType,
                                calculationType: plan?.calculationType,
                                codeExclusions: (plan?.codeExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                    feePercentage: undefined,
                                    doesNotSlide: undefined,
                                    labFee: undefined,
                                    absoluteFee: undefined,
                                    effectiveDate: undefined,
                                    expirationDate: undefined,
                                })),
                                codeRangeExclusions: (plan?.codeRangeExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                    feePercentage: undefined,
                                    labFee: undefined,
                                    absoluteFee: undefined,
                                    doesNotSlide: undefined,
                                    effectiveDate: undefined,
                                    expirationDate: undefined,
                                })),
                                codeCategoryExclusions: (plan?.codeCategoryExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                    feePercentage: undefined,
                                    labFee: undefined,
                                    absoluteFee: undefined,
                                    doesNotSlide: undefined,
                                    effectiveDate: undefined,
                                    expirationDate: undefined,
                                })),
                            });
                        }}
                    />
                    <Field.Dropdown
                        label="Copy plan with exclusions ranges and rules"
                        placeholder="Copy Plan"
                        options={[{ key: '', text: 'Select Plan' }, ...selectedPlanOption]}
                        selectedKey={rangeRulesOption?.key ?? ''}
                        onChange={(event, option) => {
                            const plan = selectedPlanList?.find((p) => p.name === option?.text);
                            //Make sure that both dropdowns are not selected at the same time
                            if (option) {
                                setRangeRulesOption(option);
                                setRangeOnlyOption(undefined);
                            }
                            setSelectedProgramPlan({
                                ...programPlan,
                                id: uuid(),
                                adjustmentType: plan?.adjustmentType,
                                calculationType: plan?.calculationType,
                                codeExclusions: (plan?.codeExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                })),
                                codeRangeExclusions: (plan?.codeRangeExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                })),
                                codeCategoryExclusions: (plan?.codeCategoryExclusions ?? []).map((c) => ({
                                    ...c,
                                    id: uuid(),
                                })),
                            });
                        }}
                    />
                </Stack>
            </Stack>
        </Panel>
    );
}

export default ProgramPlan;
