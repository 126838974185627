import { DefaultButton, Label, MessageBar, MessageBarType, PrimaryButton, Stack, Text, Toggle, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import ManageSlidingFee from './ManageSlidingFee';
import SlidingFeeBar from './components/SlidingFeeBar';
import ProgramPanel from './components/ProgramPanel';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect } from 'react';
import ISlidingFeeProgram from 'api/models/slidingFee.model';
import ProgramPlan from './components/ProgramPlan';
import { useLocation, useParams } from 'react-router';
import {
    getAthenaSlidingFeePrograms,
    getRefreshAethenaSlidingFeePrograms,
    selectRefreshAthenaOneProgramsLoading,
} from 'state/slices/sliding-fee/sliding-fee.slice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useSignalR } from 'hooks/signalR/useSignalr';
import { slidingFeeProgramSignalR } from 'hooks/signalR/signalr.config';

export default function SlidingFees() {
    const dispatch = useDispatch();
    const {
        toggleIsProgramPanelOpen,
        getSlidingFeePrograms,
        programsAsList,
        cleanupSelectedProgram,
        toggleShowProgramHistory,
        showProgramsHistory,
    } = useSlidingFee();
    const { programId, tenantId } = useParams<{ programId: string; tenantId: string }>();
    const theme = useTheme();

    const loadingAthenaOneSlidingFeePrograms = useSelector(selectRefreshAthenaOneProgramsLoading);
    const isLoadingAthenaOneSlidingFeePrograms = loadingAthenaOneSlidingFeePrograms === LoadingStatus.Pending;
    const { registerSignalRConfig } = useSignalR();

    const location = useLocation();
    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const plan = routePieces[3];

    useEffect(() => {
        getSlidingFeePrograms();
        dispatch(getAthenaSlidingFeePrograms({ tenantId }));
        registerSignalRConfig(slidingFeeProgramSignalR);
        return () => {
            cleanupSelectedProgram();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSlidingFeePrograms, cleanupSelectedProgram, tenantId, dispatch]);

    const createProgram = () => {
        toggleIsProgramPanelOpen();
    };

    const headerContent = () => {
        const defaultSlidingProgram = programsAsList.find((program) => program.isDefaultSlidingFeeProgram);
        return (
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
                <Toggle
                    label="Active Only "
                    inlineLabel
                    checked={!showProgramsHistory}
                    styles={{ root: { marginBottom: 3 } }}
                    onClick={() => toggleShowProgramHistory()}
                />
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                    <Label> Sliding Fee Program:</Label> <Text variant="medium">{defaultSlidingProgram?.name ?? 'N/A'}</Text>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <PrimaryButton iconProps={{ iconName: 'Add' }} onClick={createProgram}>
                        Create Program
                    </PrimaryButton>
                    <DefaultButton
                        disabled={isLoadingAthenaOneSlidingFeePrograms}
                        iconProps={{ iconName: 'Refresh' }}
                        onClick={() => dispatch(getRefreshAethenaSlidingFeePrograms({ tenantId }))}
                    >
                        {!isLoadingAthenaOneSlidingFeePrograms ? 'Refresh Data' : 'Loading...'}
                    </DefaultButton>
                </Stack>
            </Stack>
        );
    };

    return (
        <ActivityPage title="Programs" rightContent={headerContent()} hideFooter hasPageHeader>
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <SlidingFeeBar
                    programs={programsAsList.map((program: ISlidingFeeProgram) => ({
                        id: program.id,
                        name: program.name,
                    }))}
                />
                <Stack styles={{ root: { padding: 10, display: 'flex' } }} grow>
                    <Stack grow>
                        {!programId ? (
                            <MessageBar messageBarType={MessageBarType.info} theme={theme} title="Choose a Program">
                                There is no program selected. Click a program to manage the plans of that program.
                            </MessageBar>
                        ) : !plan ? (
                            <ManageSlidingFee />
                        ) : (
                            <ProgramPlan />
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <ProgramPanel />
        </ActivityPage>
    );
}
