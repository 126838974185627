import { Dropdown, IColumn, Link, MessageBar, MessageBarType, SelectionMode, Text } from '@fluentui/react';
import { ISlidingFeePlan } from 'api/models/slidingFee.model';
import SortableDetailsList from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { sortBy } from 'lodash';
import { NavLink, useParams } from 'react-router-dom';
import {
    selectProgramPlanViewHasDuplicateOptions,
    selectSelectedProgramAthenaOnePlanOptions,
    selectSlidingFeelPlanViewPlanList,
    setSlidingFeePlansView,
    SlidingFeePlanView,
    updateSelectedProgramPlan,
} from 'state/slices/sliding-fee/sliding-fee.slice';
import { useAppDispatch } from 'state/store';
import dateOnly from 'utils/dateOnly';

function SlidingFeesDetailsList() {
    const dispatch = useAppDispatch();
    const { tenantId, programId } = useParams<{ tenantId: string; programId: string }>();
    const { setSelectedProgramPlan, selectedProgram } = useSlidingFee();

    const plans = useSelector(selectSlidingFeelPlanViewPlanList);
    const hasDuplicateAthenaPlans = useSelector(selectProgramPlanViewHasDuplicateOptions);
    const athenaPlanOptionsForPlans = useSelector(selectSelectedProgramAthenaOnePlanOptions);

    if (!plans.length) return <MessageBar>There are no plans to display here.</MessageBar>;

    const _onItemInvoked = (item?: ISlidingFeePlan, index?: number) => {
        setSelectedProgramPlan(item, false, index);
    };

    const linkStyles: React.CSSProperties = {
        textDecoration: 'none',
    };

    const gridColumns: IColumn[] = [
        {
            key: 'name',
            fieldName: 'name',
            minWidth: 150,
            maxWidth: 200,
            name: 'Name',
            isResizable: true,
            getValueKey: (item?: ISlidingFeePlan) => {
                return item?.name ?? '';
            },
            onRender: (item?: ISlidingFeePlan) => {
                if (item) {
                    const indexOfPlan = selectedProgram?.plans?.findIndex((plan) => plan.id === item.id);
                    return (
                        <NavLink
                            to={`/${tenantId}/sliding-fee/${programId}/plan`}
                            style={linkStyles}
                            exact={true}
                            onClick={() => _onItemInvoked(item, indexOfPlan)}
                        >
                            <Link>{item?.name ?? ''}</Link>
                        </NavLink>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'percent',
            fieldName: 'percent',
            minWidth: 150,
            maxWidth: 150,
            name: '% of Poverty',
            getValueKey: (item?: ISlidingFeePlan) => {
                if (item?.povertyPercentageFrom) {
                    return `${item?.povertyPercentageFrom} `;
                }
                return '';
            },

            onRender: (item?: ISlidingFeePlan) => {
                if (item) {
                    const percentage =
                        item.povertyPercentageFrom !== undefined && item.povertyPercentageTo !== undefined
                            ? `${item.povertyPercentageFrom !== undefined ? item.povertyPercentageFrom?.toString() : ''} - ${
                                  item.povertyPercentageTo !== undefined ? item.povertyPercentageTo?.toString() : ''
                              }`
                            : 'N/A';
                    return <Text variant="smallPlus">{percentage}</Text>;
                }
            },
        },
        {
            key: 'effectiveDate',
            fieldName: 'effectiveDate',
            minWidth: 150,
            maxWidth: 150,
            name: 'Effective Date',
            onRender: (item?: ISlidingFeePlan) => {
                if (item) {
                    const date = item.effectiveDate ? dateOnly(item.effectiveDate as string) : '';
                    return <Text variant="smallPlus">{date}</Text>;
                }
            },
        },
        {
            key: 'expirationDate',
            name: 'Expiration Date',
            minWidth: 150,
            maxWidth: 150,
            fieldName: 'expirationDate',
            onRender: (item?: ISlidingFeePlan) => {
                if (item) {
                    const date = item.expirationDate ? dateOnly(item.expirationDate as string) : '';
                    return <Text variant="smallPlus">{date}</Text>;
                }
            },
        },
        {
            key: 'dentalSlidePlanMapping',
            name: 'Athena One Dental Slide Mapping',
            minWidth: 150,
            fieldName: 'athenaPlanId',
            onRender: (item?: ISlidingFeePlan) => {
                if (item) {
                    return (
                        <Dropdown
                            placeholder="(Select)"
                            options={[{ key: '', text: '(Select)' }, ...sortBy(athenaPlanOptionsForPlans, 'text')]}
                            selectedKey={item.athenaPlanId || ''}
                            disabled={!athenaPlanOptionsForPlans.length}
                            onChange={(_ev, option) => {
                                if (option?.key !== undefined)
                                    dispatch(
                                        updateSelectedProgramPlan({
                                            planId: item.id,
                                            data: { ...item, athenaPlanId: option.key as string },
                                        }),
                                    );
                            }}
                        />
                    );
                }
            },
        },
    ];

    function gotoActiveTab() {
        dispatch(setSlidingFeePlansView(SlidingFeePlanView.Active));
    }

    return (
        <>
            {hasDuplicateAthenaPlans && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    Duplicate slide mappings found. Please modify selected mappings in order to save. Go to{' '}
                    <Link onClick={gotoActiveTab}>
                        <strong> Active Plans </strong>
                    </Link>{' '}
                    tab.
                </MessageBar>
            )}
            <SortableDetailsList
                onItemInvoked={_onItemInvoked}
                items={plans}
                sortOnMount={true}
                selectionMode={SelectionMode.none}
                columns={gridColumns}
            />
        </>
    );
}

export default SlidingFeesDetailsList;
