/* eslint-disable no-console */
import { HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import useTenantId from 'hooks/useTenantId';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { AppThunk } from 'state/store';
import isDev from 'utils/isDev';

const hubConnectionString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    // transport: HttpTransportType.WebSockets,
};
export const connection = new HubConnectionBuilder().withUrl(hubConnectionString, options).build();

export enum SignalRMessage {
    LocationOfCare = 'LocationOfCare',
    TenantConfig = 'TenantConfig',
    AppointmentCancellationReason = 'AppointmentCancellationReason',
    ProviderProfile = 'ProviderProfile',
    EncounterReason = 'EncounterReason',
    RefreshSlidingFeePlansCompleted = 'RefreshSlidingFeePlansCompleted',
    TenantSetupChange = 'TenantSetupChange',
    UserTask = 'UserTask',
}

export type SignalRMessageAction = {
    message: SignalRMessage;
    action: SignalRAction;
};

export type SignalRAction = (data: any) => AnyAction | AppThunk<void> | void;

export type SignalRActionConfig = SignalRMessageAction[];

export type SignalROptions = {
    disableAutoMessageCleanup?: boolean;
};

export function useSignalR(options?: SignalROptions) {
    const dispatch = useDispatch();
    const [registeredMessages, setRegisteredMessages] = useState<string[]>([]);

    /**
     * Registers a new signalR action handler to the global connection hub.
     *
     * @param {string} signalRMessage
     * @param {((data: any) => AnyAction | AppThunk<void>)} action
     */
    const registerMessage = useCallback(
        (signalRMessage: string, action: SignalRAction) => {
            connection.off(signalRMessage); // Ensuring only ever one handler
            connection.on(signalRMessage, (data) => {
                dispatch(action(data)); //If action is a callback, this won't fire.
                action(data);
            });
        },
        [connection, dispatch],
    );

    //Handle disposing of only one message
    const disposeSignalRMessage = useCallback(
        (signalRMessage: string) => {
            connection.off(signalRMessage);
            const indexOfMessage = registeredMessages.indexOf(signalRMessage);
            if (indexOfMessage > -1)
                setRegisteredMessages([
                    ...registeredMessages.slice(0, indexOfMessage),
                    ...registeredMessages.slice(indexOfMessage + 1),
                ]);
        },
        [registeredMessages, connection],
    );

    //Handle registering only one message
    const registerSignalRMessage = useCallback(
        (signalRMessage: string, action: SignalRAction) => {
            registerMessage(signalRMessage, action);
            if (registeredMessages.indexOf(signalRMessage) === -1) setRegisteredMessages([...registeredMessages, signalRMessage]);
        },
        [registeredMessages, registerMessage],
    );

    const registerSignalRConfig = useCallback(
        (config: SignalRActionConfig) => {
            config.forEach((a) => {
                registerMessage(a.message, a.action);
            });

            const messagesToRegister = config.map((a) => a.message).filter((m) => registeredMessages.indexOf(m) === -1);
            setRegisteredMessages([...registeredMessages, ...messagesToRegister]);
        },
        [registeredMessages, registerMessage],
    );

    const cleanupMessages = useCallback(() => {
        registeredMessages.forEach((message) => {
            connection.off(message);
        });
        setRegisteredMessages([]);
    }, [connection, registeredMessages]);

    useEffect(() => {
        return () => {
            if (registeredMessages.length && !options?.disableAutoMessageCleanup) {
                cleanupMessages();
            }
        };
    }, [registeredMessages]);

    return {
        registerSignalRConfig: registerSignalRConfig,
        disposeSignalRMessage: disposeSignalRMessage,
        registerSignalRMessage: registerSignalRMessage,
    };
}

/**
 * Start/Stop the provided hub connection (on connection change or when the component is unmounted)
 * @param {HubConnection} hubConnection The signalR hub connection
 * @return {HubConnection} the current signalr connection
 * @return {any} the signalR error in case the start does not work
 */
function useHub(hubConnection?: HubConnection): { hubConnectionState: HubConnectionState; error?: string } {
    const tenantId = useTenantId();
    const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(
        hubConnection?.state ?? HubConnectionState.Disconnected,
    );
    const [error, setError] = useState();

    const currentHubState = hubConnection?.state ?? HubConnectionState.Disconnected;

    const updateConnectionState = (state: HubConnectionState) => {
        if (currentHubState !== hubConnectionState) setHubConnectionState(state);
    };

    useEffect(() => {
        updateConnectionState(currentHubState);
    }, [currentHubState]);

    useEffect(() => {
        if (!hubConnection) return;
        hubConnection.onclose(() => updateConnectionState(HubConnectionState.Disconnected));
        hubConnection.onreconnected(() => updateConnectionState(HubConnectionState.Connected));
        hubConnection.onreconnecting(() => updateConnectionState(HubConnectionState.Reconnecting));
    }, []);

    useEffect(() => {
        if (!hubConnection) return;

        if (currentHubState === HubConnectionState.Disconnected) {
            const start = hubConnection
                .start()
                .then(() => {
                    hubConnection
                        .invoke('AddConnectionToGroupAsync', hubConnection.connectionId, tenantId)
                        .then(() => console.log('Connected to tenant config...'))
                        .catch((err) => console.error(err.toString()));

                    setHubConnectionState(HubConnectionState.Connected);
                    console.log('Websocket connection established');
                })
                .catch((reason) => setError(reason));
            return () => {
                start.then(() => {
                    hubConnection.stop();
                });
            };
        }

        return () => {
            hubConnection.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection, tenantId]);

    return { hubConnectionState, error };
}

export default useHub;
