import {
    Dropdown,
    Label,
    Pivot,
    PivotItem,
    ScrollablePane,
    ScrollbarVisibility,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
} from '@fluentui/react';
import { Section } from 'components';
import { useSelector } from 'hooks';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import {
    clearSelectedProgramPlansAthenaOnePlanMappings,
    selectAthenaOneProgramsOptions,
    selectSelectedActivePlanList,
    selectSelectedExpiredPlanList,
    selectSelectedFuturePlanList,
    selectSlidingFeePlansView,
    setSlidingFeePlansView,
    SlidingFeePlanView,
    updateSelectedProgramProp,
} from 'state/slices/sliding-fee/sliding-fee.slice';
import { useAppDispatch } from 'state/store';
import ProgramPlanPanel from './components/ProgramPlanPanel';
import SlidingFeesDetailsList from './components/SlidingFeeDetailsList';
import SlidingFeesToolbar from './components/SlidingFeeToolbar';
import ManageProgram from './ManageProgram';

function ManageSlidingFee() {
    const dispatch = useAppDispatch();
    const { selectedProgramLoading, selectedProgram } = useSlidingFee();

    const activePlans = useSelector(selectSelectedActivePlanList);
    const futurePlans = useSelector(selectSelectedFuturePlanList);
    const expiredPlans = useSelector(selectSelectedExpiredPlanList);
    const selectedPlanView = useSelector(selectSlidingFeePlansView);

    const options = useSelector(selectAthenaOneProgramsOptions);

    useEffect(() => {
        dispatch(setSlidingFeePlansView(SlidingFeePlanView.Active));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProgram?.id]);

    return (
        <>
            {selectedProgramLoading === LoadingStatus.Pending ? (
                <Spinner style={{ marginBottom: 12 }} size={SpinnerSize.large} label="Loading plans..." labelPosition="right" />
            ) : (
                <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <Section
                            headingRightContent={
                                <Stack tokens={{ childrenGap: 20 }} horizontal wrap>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <Label htmlFor="aOneDentalSlideProgramDropdown">Athena One Dental Slide Program:</Label>
                                        <Dropdown
                                            styles={{ root: { minWidth: 200 } }}
                                            id="aOneDentalSlideProgramDropdown"
                                            placeholder="(Select)"
                                            options={options}
                                            selectedKey={selectedProgram?.athenaProgramId || ''}
                                            onChange={(_ev, option) => {
                                                dispatch(
                                                    updateSelectedProgramProp({ path: 'athenaProgramId', value: option?.key }),
                                                );
                                                dispatch(clearSelectedProgramPlansAthenaOnePlanMappings());
                                            }}
                                        />
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                                        <Label htmlFor="aOneDentalSlidePayerId">Program Id: </Label>
                                        <TextField
                                            readOnly
                                            id="aOneDentalSlidePayerId"
                                            value={selectedProgram?.athenaProgramId || 'N/A'}
                                        />
                                    </Stack>
                                </Stack>
                            }
                            heading={'Details'}
                        >
                            <Stack tokens={{ childrenGap: 10 }}>
                                <ManageProgram />
                            </Stack>
                        </Section>
                        <Section heading={'Plans'} headingRightContent={<SlidingFeesToolbar />}>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Pivot
                                    onLinkClick={(item) =>
                                        item && dispatch(setSlidingFeePlansView(item.props.itemProp as SlidingFeePlanView))
                                    }
                                    selectedKey={selectedPlanView}
                                >
                                    <PivotItem
                                        itemKey={SlidingFeePlanView.Active}
                                        headerText={`Active Plans (${activePlans.length})`}
                                        itemProp={SlidingFeePlanView.Active}
                                    />
                                    <PivotItem
                                        itemKey={SlidingFeePlanView.Future}
                                        headerText={`Future Plans (${futurePlans.length})`}
                                        itemProp={SlidingFeePlanView.Future}
                                    />
                                    <PivotItem
                                        itemKey={SlidingFeePlanView.Expired}
                                        headerText={`Expired Plans (${expiredPlans.length})`}
                                        itemProp={SlidingFeePlanView.Expired}
                                    />
                                </Pivot>
                                <SlidingFeesDetailsList />
                            </Stack>
                        </Section>
                    </ScrollablePane>
                </Stack>
            )}
            <ProgramPlanPanel />
        </>
    );
}

export default ManageSlidingFee;
