import { getTenantUserTasksByTarget, handleUserTaskMessage } from 'state/slices/tasks/actions';
import { SignalRActionConfig, SignalRMessage } from './useSignalr';
import { getTenantConfigsMessage } from 'state/slices/tenant/actions';
import { getAthenaSlidingFeeProgramsForCurrentTenant } from 'state/slices/sliding-fee/sliding-fee.slice';

export const locationsOfCareSignalR: SignalRActionConfig = [
    {
        message: SignalRMessage.LocationOfCare,
        action: getTenantUserTasksByTarget,
    },
];

export const slidingFeeProgramSignalR: SignalRActionConfig = [
    {
        message: SignalRMessage.RefreshSlidingFeePlansCompleted,
        action: getAthenaSlidingFeeProgramsForCurrentTenant,
    },
];

export const appLoaderSignalR: SignalRActionConfig = [
    {
        message: SignalRMessage.TenantSetupChange,
        action: getTenantConfigsMessage,
    },
    {
        message: SignalRMessage.UserTask,
        action: handleUserTaskMessage,
    },
];
